<template>
    <subspecialties-component/>
</template>

<script>
    import SubspecialtiesComponent from "@/components/admin/catalogs/SubspecialtiesComponent";
    
    export default {
        name: "Subspecialties",
        title: "Subespecialidades | Turismo BC",
        components: {SubspecialtiesComponent},
    }
</script>

<style scoped>

</style>